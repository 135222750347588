<template>
  <div class="Role_Jurisdiction">
    权限设置
  </div>
</template>
<script>
export default {
  name: 'Role_Jurisdiction',
  data () {
    return {}
  },
  methods: {}
}
</script>
<style lang="less" scoped>
.Role_Jurisdiction {
}
</style>
